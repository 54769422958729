export enum RecordingState {
    IDLE = 'idle',
    RECORDING = 'recording',
    PAUSED = 'paused',
    CANCELLED = 'cancelled',
}

export const EVENTS = {
    USER_LOGGED_IN: 'USER_LOGGED_IN',
    NEW_MESSAGE: 'NEW_MESSAGE',
    START_OR_STOP_RECORDING: 'START_OR_STOP_RECORDING',
    START_RECORDING: 'START_RECORDING',
    STARTED_RECORDING: 'STARTED_RECORDING',
    STOP_RECORDING: 'STOP_RECORDING',
    STOPPED_RECORDING: 'STOPPED_RECORDING',
    PAUSED_RECORDING: 'PAUSED_RECORDING',
    RESUMED_RECORDING: 'RESUMED_RECORDING',
    EXPAND_NOTE_TAKER: 'EXPAND_NOTE_TAKER',
    EXPAND_TTSORSTT: 'EXPAND_TTSORSTT',
    CANCEL_RECORDING: 'CANCEL_RECORDING',
    SPEECH_GENERATED: 'SPEECH_GENERATED',
    TRANSCRIPTION_COMPLETED: 'TRANSCRIPTION_COMPLETED',
    NOTE_SAVED: 'NOTE_SAVED',
    AUDIO_TRIMMED: 'AUDIO_TRIMMED',
    SHARED_FILE_RECEIVED: 'SHARED_FILE_RECEIVED',
} as const;

export type EventType = typeof EVENTS[keyof typeof EVENTS];
