import {create} from 'zustand';
import {toast} from 'react-hot-toast';
import {__} from '@/utils/translationUtils';
import {Note} from "@/lib/notes/Note";

type SortOption = 'date_desc' | 'date_asc' | 'title_asc' | 'title_desc';

interface SearchHistoryState {
    items: Note[];
    isLoading: boolean;
    error: string | null;
    hasMore: boolean;
    query: string;
    filter: 'all' | 'archived' | 'unarchived';
    sortOption: SortOption;
    setItems: (items: Note[]) => void;
    addItems: (newItems: Note[], position: 'start' | 'end') => void;
    setIsLoading: (isLoading: boolean) => void;
    setError: (error: string | null) => void;
    setHasMore: (hasMore: boolean) => void;
    setQuery: (query: string) => void;
    setFilter: (filter: 'all' | 'archived' | 'unarchived') => void;
    setSortOption: (sortOption: SortOption) => void;
    resetState: () => void;
    toggleArchive: (id: string) => Promise<void>;
    updateItem: (updatedItem: Note) => void;
    searchItems: (newQuery: string, newFilter: 'all' | 'archived' | 'unarchived', force: boolean) => Promise<void>;
    loadNewer: () => void;
    loadOlder: () => void;
    fetchItems: (newQuery: string, newFilter: 'all' | 'archived' | 'unarchived', cursor: string | null, direction: 'next' | 'prev') => Promise<void>;
    getNextCursor: () => string | null;
    getPrevCursor: () => string | null;
    bulkDelete: (ids: string[]) => Promise<void>;
    bulkArchive: (ids: string[], archive: boolean) => Promise<void>;
    deleteItem: (id: string) => Promise<void>;
}

const initialState = {
    items: [],
    isLoading: false,
    error: null,
    hasMore: true,
    query: '',
    filter: 'all' as const,
    sortOption: 'date_desc' as SortOption,
};

const sortAndDeduplicateItems = (items: Note[]): Note[] => {
    const uniqueItems = Array.from(new Map(items.map(item => [item.id, {
        ...item,
        versions: item.versions ? [...item.versions] : [],
        audios: item.audios ? [...item.audios] : []
    }])).values());
    return uniqueItems.sort((a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    ).map(sortItemVersions);
};

const sortItemVersions = (item: Note): Note => {
    const sortedItem = {
        ...item,
        versions: item.versions.sort((a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
    };
    return sortedItem;
};

const MAX_RETRIES = 10; // Increased from 5 to 10
const INITIAL_BACKOFF = 1000; // 1 second
const MAX_BACKOFF = 60000; // 1 minute

const useSearchHistoryStore = create<SearchHistoryState>((set, get) => ({
    ...initialState,
    setItems: (items) => set({items: sortAndDeduplicateItems(items)}),
    addItems: (newItems, position) => set((state) => {
        const allItems = [...state.items, ...newItems];
        return {items: sortAndDeduplicateItems(allItems)};
    }),
    setIsLoading: (isLoading) => set({isLoading}),
    setError: (error) => set({error}),
    setHasMore: (hasMore) => set({hasMore}),
    setQuery: (query) => set({query}),
    setFilter: (filter) => set({filter}),
    setSortOption: (sortOption) => set((state) => ({
        sortOption,
        items: sortAndDeduplicateItems(state.items)
    })),
    resetState: () => set(initialState),
    toggleArchive: async (id) => {
        let retries = 0;
        let backoff = INITIAL_BACKOFF;

        const toggleWithRetry = async (): Promise<void> => {
            try {
                const item = get().items.find(item => item.id === id);
                if (!item) throw new Error(__('Item not found', {source: 'core'}));

                const response = await fetch('/api/audio/archive', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        id,
                        isArchived: !item.isArchived
                    }),
                });

                if (!response.ok) throw new Error(__('Failed to toggle archive status', {source: 'core'}));

                const updatedItem = await response.json() as { isArchived: boolean };
                set((state) => ({
                    items: sortAndDeduplicateItems(state.items.map((i) =>
                        i.id === id ? {...i, isArchived: updatedItem.isArchived, updatedAt: new Date()} : i
                    ).filter((i) => {
                        if (state.filter === 'unarchived' && i.isArchived) return false;
                        if (state.filter === 'archived' && !i.isArchived) return false;
                        return true;
                    })),
                }));
                toast.success(__(updatedItem.isArchived ? 'Item archived' : 'Item unarchived', {source: 'core'}));
            } catch (error) {
                console.error(__('Error toggling archive status:', {source: 'core'}), error);

                if (retries < MAX_RETRIES) {
                    retries++;
                    console.log(__('Retrying in {{backoff}}ms... (Attempt {{retries}} of {{maxRetries}})', {
                        source: 'core',
                        placeholders: {
                            backoff: backoff.toString(),
                            retries: retries.toString(),
                            maxRetries: MAX_RETRIES.toString()
                        }
                    }));
                    await new Promise(resolve => setTimeout(resolve, backoff));
                    backoff *= 2; // Exponential backoff
                    return toggleWithRetry();
                } else {
                    toast.error(__('Failed to update archive status', {source: 'core'}));
                    throw error;
                }
            }
        };

        await toggleWithRetry();
    },
    updateItem: (updatedItem: Note) => set((state) => {
        const sortedItem = sortItemVersions(updatedItem);
        return {
            items: sortAndDeduplicateItems(state.items.map((item) =>
                item.id === sortedItem.id ? {...sortedItem, updatedAt: new Date()} : item
            ))
        };
    }),
    deleteItem: async (id: string) => {
        let retries = 0;
        let backoff = INITIAL_BACKOFF;

        const deleteWithRetry = async (): Promise<void> => {
            try {
                const response = await fetch(`/api/notes/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Retry-After': backoff.toString(),
                        'Connection-Timeout': '10000'
                    }
                });
                
                if (response.status === 500 || response.status === 503) {
                    const errorData = await response.json();
                    if (errorData.error?.includes('timeout') || errorData.error?.includes('connection')) {
                        throw new Error('database_timeout');
                    }
                }
                
                if (!response.ok) throw new Error(__('Failed to delete item', {source: 'core'}));

                set((state) => ({
                    items: state.items.filter(item => item.id !== id)
                }));

                toast.success(__('Item deleted successfully', {source: 'core'}));
            } catch (error) {
                console.error(__('Error deleting item:', {source: 'core'}), error);

                const isTimeout = error instanceof Error && 
                    (error.message === 'database_timeout' || 
                     error.message.includes('timeout') || 
                     error.message.includes('connection'));

                if (retries < MAX_RETRIES) {
                    retries++;
                    if (isTimeout) {
                        backoff = Math.min(backoff * 3, MAX_BACKOFF);
                    } else {
                        backoff = Math.min(backoff * 2, MAX_BACKOFF);
                    }
                    
                    console.log(__('Retrying in {{backoff}}ms... (Attempt {{retries}} of {{maxRetries}})', {
                        source: 'core',
                        placeholders: {
                            backoff: backoff.toString(),
                            retries: retries.toString(),
                            maxRetries: MAX_RETRIES.toString()
                        }
                    }));
                    await new Promise(resolve => setTimeout(resolve, backoff));
                    return deleteWithRetry();
                } else {
                    const errorMessage = isTimeout ? 
                        __('Database connection timeout. Please try again later.', {source: 'core'}) :
                        __('Failed to delete item', {source: 'core'});
                    toast.error(errorMessage);
                    throw error;
                }
            }
        };

        await deleteWithRetry();
    },
    searchItems: async (newQuery: string, newFilter: 'all' | 'archived' | 'unarchived', force: boolean) => {
        const {query, filter, isLoading} = get();
        if (isLoading || (!force && newQuery === query && newFilter === filter)) return;

        set({isLoading: true, error: null});

        let retries = 0;
        let backoff = INITIAL_BACKOFF;

        const searchWithRetry = async (): Promise<void> => {
            try {
                const result = await fetch(`/api/search?query=${encodeURIComponent(newQuery)}&filter=${newFilter}&limit=12`);
                if (!result.ok) throw new Error(__('Failed to fetch items', {source: 'core'}));
                const data = await result.json();
                set({
                    items: sortAndDeduplicateItems(data.items),
                    hasMore: !!data.nextCursor,
                    query: newQuery,
                    filter: newFilter,
                    isLoading: false,
                });
            } catch (error) {
                console.error(__('Error fetching search results:', {source: 'core'}), error);

                if (retries < MAX_RETRIES) {
                    retries++;
                    console.log(__('Retrying in {{backoff}}ms... (Attempt {{retries}} of {{maxRetries}})', {
                        source: 'core',
                        placeholders: {
                            backoff: backoff.toString(),
                            retries: retries.toString(),
                            maxRetries: MAX_RETRIES.toString()
                        }
                    }));
                    await new Promise(resolve => setTimeout(resolve, backoff));
                    backoff *= 2; // Exponential backoff
                    return searchWithRetry();
                } else {
                    set({error: __('Failed to fetch items', {source: 'core'}), isLoading: false});
                    toast.error(__('Failed to fetch items', {source: 'core'}));
                }
            }
        };

        await searchWithRetry();
    },
    getNextCursor: () => {
        const items = get().items;
        if (items.length === 0) return null;
        return new Date(items[items.length - 1].updatedAt).toISOString();
    },
    getPrevCursor: () => {
        const items = get().items;
        if (items.length === 0) return null;
        return new Date(items[0].updatedAt).toISOString();
    },
    loadNewer: () => {
        const {items, query, filter, isLoading, error} = get();
        if (items.length === 0) {
            get().searchItems(query, filter, true);
        } else if (!isLoading && !error) {
            const prevCursor = get().getPrevCursor();
            if (prevCursor) {
                get().fetchItems(query, filter, prevCursor, 'prev');
            }
        }
    },
    loadOlder: () => {
        const {items, query, filter, hasMore, isLoading, error} = get();
        if (items.length === 0) {
            get().searchItems(query, filter, true);
        } else if (hasMore && !isLoading && !error) {
            const nextCursor = get().getNextCursor();
            if (nextCursor) {
                get().fetchItems(query, filter, nextCursor, 'next');
            } else {
                set({hasMore: false});
            }
        }
    },
    fetchItems: async (newQuery: string, newFilter: 'all' | 'archived' | 'unarchived', cursor: string | null, direction: 'next' | 'prev') => {
        let retries = 0;
        let backoff = INITIAL_BACKOFF;

        const fetchWithRetry = async (): Promise<void> => {
            try {
                set({isLoading: true});
                const response = await fetch(`/api/search?query=${encodeURIComponent(newQuery)}&cursor=${cursor || ''}&direction=${direction}&limit=12&filter=${newFilter}`, {
                    headers: {
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache'
                    }
                });

                if (!response.ok) {
                    throw new Error(__('HTTP error! status: {{status}}', {
                        source: 'core',
                        placeholders: {status: response.status.toString()}
                    }));
                }

                const data = await response.json();

                const currentItems = get().items;
                let newItems: Note[];

                if (direction === 'prev') {
                    newItems = [...data.items, ...currentItems];
                } else {
                    newItems = [...currentItems, ...data.items];
                }

                const sortedItems = sortAndDeduplicateItems(newItems);

                set({
                    items: sortedItems,
                    hasMore: direction === 'next' ? !!data.nextCursor : get().hasMore,
                    query: newQuery,
                    filter: newFilter,
                    error: null,
                    isLoading: false
                });
            } catch (error) {
                console.error(__('Error fetching search results:', {source: 'core'}), error);

                if (retries < MAX_RETRIES) {
                    retries++;
                    backoff = Math.min(backoff * 2, MAX_BACKOFF);
                    console.log(__('Retrying in {{backoff}}ms... (Attempt {{retries}} of {{maxRetries}})', {
                        source: 'core',
                        placeholders: {
                            backoff: backoff.toString(),
                            retries: retries.toString(),
                            maxRetries: MAX_RETRIES.toString()
                        }
                    }));
                    await new Promise(resolve => setTimeout(resolve, backoff));
                    return fetchWithRetry();
                } else {
                    set({
                        error: __('Failed to load search results. Please check your connection and try again later.', {source: 'core'}),
                        hasMore: false, // Prevent further load attempts
                        isLoading: false
                    });
                    toast.error(__('Failed to load search results. Please try again later.', {source: 'core'}));
                }
            }
        };

        await fetchWithRetry();
    },

    bulkDelete: async (ids: string[]) => {
        let retries = 0;
        let backoff = INITIAL_BACKOFF;

        const bulkDeleteWithRetry = async (): Promise<void> => {
            try {
                const response = await fetch('/api/recordings/bulk-delete', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({ids}),
                });
                if (!response.ok) throw new Error(__('Failed to delete items', {source: 'core'}));

                set((state) => ({
                    items: state.items.filter(item => !ids.includes(item.id))
                }));

                toast.success(__('{{count}} items deleted successfully', {
                    source: 'core',
                    placeholders: {count: ids.length.toString()}
                }));
            } catch (error) {
                console.error(__('Error deleting items:', {source: 'core'}), error);

                if (retries < MAX_RETRIES) {
                    retries++;
                    console.log(__('Retrying in {{backoff}}ms... (Attempt {{retries}} of {{maxRetries}})', {
                        source: 'core',
                        placeholders: {
                            backoff: backoff.toString(),
                            retries: retries.toString(),
                            maxRetries: MAX_RETRIES.toString()
                        }
                    }));
                    await new Promise(resolve => setTimeout(resolve, backoff));
                    backoff *= 2; // Exponential backoff
                    return bulkDeleteWithRetry();
                } else {
                    toast.error(__('Failed to delete items', {source: 'core'}));
                    throw error;
                }
            }
        };

        await bulkDeleteWithRetry();
    },
    bulkArchive: async (ids: string[], archive: boolean) => {
        let retries = 0;
        let backoff = INITIAL_BACKOFF;

        const bulkArchiveWithRetry = async (): Promise<void> => {
            try {
                const response = await fetch('/api/recordings/bulk-archive', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({ids, archive}),
                });
                if (!response.ok) throw new Error(__('Failed to update archive status', {source: 'core'}));

                set((state) => ({
                    items: sortAndDeduplicateItems(state.items.map(item => {
                        if (ids.includes(item.id)) {
                            return {
                                ...item,
                                isArchived: archive,
                                updatedAt: new Date()
                            };
                        }
                        return item;
                    }).filter(item => {
                        if (state.filter === 'unarchived' && item.isArchived) return false;
                        if (state.filter === 'archived' && !item.isArchived) return false;
                        return true;
                    }))
                }));

                toast.success(__(archive ? '{{count}} items archived successfully' : '{{count}} items unarchived successfully', {
                    source: 'core',
                    placeholders: {count: ids.length.toString()}
                }));
            } catch (error) {
                console.error(__('Error updating archive status:', {source: 'core'}), error);

                if (retries < MAX_RETRIES) {
                    retries++;
                    console.log(__('Retrying in {{backoff}}ms... (Attempt {{retries}} of {{maxRetries}})', {
                        source: 'core',
                        placeholders: {
                            backoff: backoff.toString(),
                            retries: retries.toString(),
                            maxRetries: MAX_RETRIES.toString()
                        }
                    }));
                    await new Promise(resolve => setTimeout(resolve, backoff));
                    backoff *= 2; // Exponential backoff
                    return bulkArchiveWithRetry();
                } else {
                    toast.error(__('Failed to update archive status', {source: 'core'}));
                    throw error;
                }
            }
        };

        await bulkArchiveWithRetry();
    },

}));

export const useSearchHistory = () => {
    const {
        items,
        isLoading,
        error,
        hasMore,
        query,
        filter,
        sortOption,
        setItems,
        addItems,
        setIsLoading,
        setError,
        setHasMore,
        setQuery,
        setFilter,
        setSortOption,
        resetState,
        toggleArchive,
        updateItem,
        deleteItem,
        searchItems,
        loadNewer,
        loadOlder,
        bulkDelete,
        bulkArchive
    } = useSearchHistoryStore();

    return {
        items,
        isLoading,
        error,
        hasMore,
        query,
        filter,
        sortOption,
        searchItems,
        loadNewer,
        loadOlder,
        deleteItem,
        toggleArchive,
        setSortOption,
        updateItem,
        bulkDelete,
        bulkArchive,
    };
};

export default useSearchHistoryStore;
