'use client';

import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

type FocusContextType = {
  currentFocus: string | null;
  requestFocus: (id: string) => void;
  releaseFocus: (id: string) => void;
  hasFocus: (id: string) => boolean;
};

const FocusContext = createContext<FocusContextType | null>(null);

export function FocusProvider({ children }: { children: React.ReactNode }) {
  const [currentFocus, setCurrentFocus] = useState<string | null>(null);

  const requestFocus = useCallback((id: string) => {
    console.log('FocusContext requestFocus:', id, 'current:', currentFocus);
    setCurrentFocus(id);
  }, [currentFocus]);

  const releaseFocus = useCallback((id: string) => {
    console.log('FocusContext releaseFocus:', id, 'current:', currentFocus);
    setCurrentFocus(current => {
      // Only release if this component still has focus
      if (current === id) {
        console.log('FocusContext releasing focus for:', id);
        return null;
      }
      return current;
    });
  }, [currentFocus]);

  const hasFocus = useCallback((id: string) => {
    const has = currentFocus === id;
    console.log('FocusContext hasFocus:', id, 'result:', has, 'current:', currentFocus);
    return has;
  }, [currentFocus]);

  // Debug effect to track focus changes
  useEffect(() => {
    console.log('FocusContext focus changed to:', currentFocus);
  }, [currentFocus]);

  return (
    <FocusContext.Provider value={{ currentFocus, requestFocus, releaseFocus, hasFocus }}>
      {children}
    </FocusContext.Provider>
  );
}

export function useFocus() {
  const context = useContext(FocusContext);
  if (!context) {
    throw new Error('useFocus must be used within a FocusProvider');
  }
  return context;
} 