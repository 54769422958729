'use client';

import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { __, TranslationOptions } from '@/utils/translationUtils';

interface LoadingSpinnerProps {
  title?: string;
  messages?: string[];
  messageDelay?: number;
  size?: number | string;
  thickness?: number;
  speed?: number;
  className?: string;
  fullViewport?: boolean;
  loopMessages?: boolean;
  direction?: 'row' | 'column';
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  title,
  messages = [],
  messageDelay = 3000,
  size = '1em',
  thickness = 2,
  speed = 1,
  className = '',
  fullViewport = false,
  loopMessages = false,
  direction = 'column',
}) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const translationOptions: TranslationOptions = { source: 'uiComponents' };

  useEffect(() => {
    setIsMounted(true);
    if (messages.length <= 1) return;

    const intervalId = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => {
        if (loopMessages) {
          return (prevIndex + 1) % messages.length;
        } else {
          return prevIndex < messages.length - 1 ? prevIndex + 1 : prevIndex;
        }
      });
    }, messageDelay);

    return () => clearInterval(intervalId);
  }, [messages, messageDelay, loopMessages]);

  const mobileSize = isMobile ? '0.75em' : size;
  const mobileThickness = isMobile ? Math.max(1, thickness - 1) : thickness;

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: direction === 'column' ? 'column' : 'row',
    fontSize: 'inherit',
    lineHeight: 1,
    ...(fullViewport ? {
      position: 'fixed',
      inset: 0,
      zIndex: 9999,
      backgroundColor: 'white',
    } : {}),
  };

  const spinnerStyle: React.CSSProperties = {
    width: mobileSize,
    height: mobileSize,
    animationDuration: `${1 / speed}s`,
    animationName: 'spin',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    borderRadius: '50%',
    borderWidth: `${mobileThickness}px`,
    borderStyle: 'solid',
    borderColor: 'currentColor',
    borderTopColor: 'transparent',
    opacity: 0.5,
  };

  const titleStyle: React.CSSProperties = {
    fontSize: '0.875em',
    fontWeight: 500,
    color: 'currentColor',
    ...(direction === 'column' ? { marginTop: '0.25em' } : { marginLeft: '0.25em' }),
  };

  const messageStyle: React.CSSProperties = {
    fontSize: '0.75em',
    color: 'currentColor',
    opacity: 0.7,
    transition: 'opacity 300ms',
    ...(direction === 'column' ? { marginTop: '0.125em' } : { marginLeft: '0.25em' }),
  };

  if (!isMounted) {
    return null;
  }

  return (
    <div style={containerStyle} className={`loading-spinner ${className}`} role="status">
      <div style={spinnerStyle}></div>
      {title && <h2 style={titleStyle}>{__(title, translationOptions)}</h2>}
      {messages.length > 0 && (
        <p style={messageStyle} aria-live="polite">
          {__(messages[currentMessageIndex], translationOptions)}
        </p>
      )}
      <span className="sr-only">{__('Loading', translationOptions)}</span>
    </div>
  );
};

export default LoadingSpinner;