'use client';

import React, {createContext, useCallback, useContext, useEffect, useState, useRef} from 'react';
import {signOut, useSession} from 'next-auth/react';
import {UserCreditsData} from "@/types/api";

interface CreditsContextType {
    credits: number | null;
    isLoading: boolean;
    error: string | null;
    fetchCredits: () => Promise<void>;
    getCredits: () => Promise<UserCreditsData | null>;
    ensureCredits: () => Promise<number | null>;
    rawCreditsData: UserCreditsData | null;
    insufficientCredits: boolean;
}

const CreditsContext = createContext<CreditsContextType | undefined>(undefined);

const DEBOUNCE_DELAY = 1000; // 1 second delay
const MAX_RETRIES = 3;
const INITIAL_BACKOFF = 1000; // 1 second

export const CreditsProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const {status} = useSession();
    const [credits, setCredits] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false); // Start as false since we don't need initial loading state
    const [error, setError] = useState<string | null>(null);
    const [rawCreditsData, setRawCreditsData] = useState<UserCreditsData | null>(null);

    // Use refs for retry logic to avoid re-renders
    const retryCountRef = useRef(0);
    const backoffDelayRef = useRef(INITIAL_BACKOFF);
    const isFetchingRef = useRef(false);
    const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

    const fetchCredits = useCallback(async (showLoading = true) => {
        if (isFetchingRef.current) return; // Prevent concurrent fetches
        
        isFetchingRef.current = true;
        if (showLoading) setIsLoading(true);
        setError(null);
        
        try {
            const response = await fetch('/api/user/credits', {
                cache: 'no-store',
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.userDeleted) {
                    const serializedError = encodeURIComponent(JSON.stringify(errorData));
                    await signOut({callbackUrl: `/?user_deleted=true&serializedError=${serializedError}`});
                    throw new Error('User has been deleted');
                }
                throw new Error(errorData.error || 'Failed to fetch credits');
            }
            const data: UserCreditsData = await response.json();
            setCredits(data.availableCredits);
            setRawCreditsData(data);
            retryCountRef.current = 0;
            backoffDelayRef.current = INITIAL_BACKOFF;
        } catch (error: unknown) {
            if (error instanceof Error) {
                if (error.message === 'User has been deleted') {
                    setError(error.message);
                    setCredits(null);
                    setRawCreditsData(null);
                } else if (retryCountRef.current < MAX_RETRIES) {
                    retryCountRef.current++;
                    setTimeout(() => fetchCredits(showLoading), backoffDelayRef.current);
                    backoffDelayRef.current *= 2;
                } else {
                    setError(error.message);
                    retryCountRef.current = 0;
                    backoffDelayRef.current = INITIAL_BACKOFF;
                }
            } else {
                setError('An unknown error occurred');
            }
        } finally {
            if (showLoading) setIsLoading(false);
            isFetchingRef.current = false;
        }
    }, []);

    const debouncedFetchCredits = useCallback(() => {
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }
        return new Promise<void>((resolve) => {
            debounceTimerRef.current = setTimeout(async () => {
                await fetchCredits(false); // Don't show loading state for background refreshes
                resolve();
            }, DEBOUNCE_DELAY);
        });
    }, [fetchCredits]);

    const getCredits = useCallback(async (): Promise<UserCreditsData | null> => {
        if (rawCreditsData === null) {
            await fetchCredits(true);
        } else {
            // Background refresh if we already have data
            fetchCredits(false);
        }
        return rawCreditsData;
    }, [rawCreditsData, fetchCredits]);

    const ensureCredits = useCallback(async (): Promise<number | null> => {
        if (credits === null) {
            await fetchCredits(true);
        } else {
            // Background refresh if we already have data
            fetchCredits(false);
        }
        return credits;
    }, [credits, fetchCredits]);

    useEffect(() => {
        if (status === 'authenticated') {
            fetchCredits(true);
        } else if (status === 'unauthenticated') {
            setIsLoading(false);
            setCredits(null);
            setRawCreditsData(null);
        }
    }, [status, fetchCredits]);

    const value: CreditsContextType = {
        credits,
        isLoading,
        error,
        fetchCredits: debouncedFetchCredits,
        getCredits,
        ensureCredits,
        rawCreditsData,
        insufficientCredits: credits !== null && credits <= 0,
    };

    return <CreditsContext.Provider value={value}>{children}</CreditsContext.Provider>;
};

export const useCredits = () => {
    const context = useContext(CreditsContext);
    if (context === undefined) {
        throw new Error('useCredits must be used within a CreditsProvider');
    }
    return context;
};