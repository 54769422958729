"use client";

import React, { useState, useCallback, useMemo, useRef, useEffect, forwardRef, ChangeEvent, MouseEvent, FocusEvent } from "react";
import {
  Root,
  Trigger,
  Value,
  Icon,
  Portal,
  Content,
  Viewport,
  Item,
  ItemIndicator,
  ItemText,
  SelectProps,
} from "@radix-ui/react-select";
import { Check, ChevronDown, Search } from "lucide-react";
import { cn } from "@/lib/utils";
import { useFocus } from "@/contexts/FocusContext";
import { DismissableLayer } from "@radix-ui/react-dismissable-layer";

export interface Select2Option {
  value: string;
  label: string;
  disabled?: boolean;
  description?: string;
}

interface Select2Props extends Omit<SelectProps, "children"> {
  options: Select2Option[];
  placeholder?: string;
  className?: string;
  searchPlaceholder?: string;
  noOptionsMessage?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  onSearch?: (searchTerm: string) => void;
  customOptionRender?: (option: Select2Option) => React.ReactNode;
  autoFocusSearch?: boolean;
  filterOptions?: (option: Select2Option) => boolean;
  required?: boolean;
  needsAttention?: boolean;
}

export const SELECT2_FOCUS_ID = "select2-search";

const Select2 = forwardRef<HTMLButtonElement, Select2Props>(
  (
    {
      options,
      placeholder = "Select an option",
      searchPlaceholder = "Search...",
      noOptionsMessage = "No options found",
      className,
      isSearchable = true,
      isClearable = false,
      isLoading = false,
      onSearch,
      customOptionRender,
      autoFocusSearch = false,
      filterOptions,
      required = false,
      needsAttention = false,
      ...props
    },
    ref
  ) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isBlinking, setIsBlinking] = useState(false);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const { requestFocus, releaseFocus, hasFocus } = useFocus();

    // Handle attention request
    useEffect(() => {
      if (needsAttention && !isOpen && required && !props.value) {
        setIsBlinking(true);
        setTimeout(() => setIsBlinking(false), 1000);
      }
    }, [needsAttention, isOpen, required, props.value]);

    // Request focus when dropdown opens
    useEffect(() => {
      if (isOpen && autoFocusSearch) {
        requestFocus(SELECT2_FOCUS_ID);
        setTimeout(() => {
          if (searchInputRef.current && hasFocus(SELECT2_FOCUS_ID)) {
            searchInputRef.current.focus();
          }
        }, 50);
      } else {
        releaseFocus(SELECT2_FOCUS_ID);
      }
    }, [isOpen, autoFocusSearch, requestFocus, releaseFocus, hasFocus]);

    const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      console.log("Select2 handleSearch - hasFocus:", hasFocus(SELECT2_FOCUS_ID));
      if (!hasFocus(SELECT2_FOCUS_ID)) {
        console.log("Select2 handleSearch - no focus, requesting focus");
        requestFocus(SELECT2_FOCUS_ID);
        e.currentTarget.focus();
        return;
      }
      const value = e.target.value;
      setSearchTerm(value);
      onSearch?.(value);
    }, [onSearch, hasFocus, requestFocus]);

    const handleInputClick = useCallback((e: MouseEvent<HTMLInputElement>) => {
      console.log("Select2 handleInputClick");
      e.stopPropagation();
      e.preventDefault();
      requestFocus(SELECT2_FOCUS_ID);
      e.currentTarget.focus();
    }, [requestFocus]);

    // Add focus monitoring
    useEffect(() => {
      const input = searchInputRef.current;
      if (!input) return;

      const handleFocusIn = () => {
        console.log("Select2 input focusin");
        requestFocus(SELECT2_FOCUS_ID);
      };

      const handleFocusOut = () => {
        console.log("Select2 input focusout");
        // Only release if we're not still open
        if (!isOpen) {
          releaseFocus(SELECT2_FOCUS_ID);
        }
      };

      input.addEventListener("focusin", handleFocusIn);
      input.addEventListener("focusout", handleFocusOut);

      return () => {
        input.removeEventListener("focusin", handleFocusIn);
        input.removeEventListener("focusout", handleFocusOut);
      };
    }, [requestFocus, releaseFocus, isOpen]);

    const filteredOptions = useMemo(() => {
      let filtered = options;

      // Apply custom filter if provided
      if (filterOptions) {
        filtered = filtered.filter(filterOptions);
      }

      // Apply search filter
      if (searchTerm) {
        filtered = filtered.filter(
          (option) =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
            option.value.toLowerCase().includes(searchTerm.toLowerCase()) ||
            option.description?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      return filtered;
    }, [options, searchTerm, filterOptions]);

    return (
      <Root
        {...props}
        onOpenChange={(open) => {
          setIsOpen(open);
          if (!open) {
            setSearchTerm("");
            releaseFocus(SELECT2_FOCUS_ID);
          }
          props.onOpenChange?.(open);
        }}
      >
        <Trigger
          ref={ref}
          className={cn(
            "flex h-10 w-full items-center justify-between rounded-md border bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            required && !props.value && "border-orange-500 dark:border-orange-400",
            isBlinking && "animate-[pulse_0.5s_ease-in-out_2]",
            className
          )}
        >
          <Value placeholder={placeholder} />
          <div className="flex items-center gap-2">
            {required && !props.value && (
              <span className="text-orange-500 dark:text-orange-400 text-sm">Required</span>
            )}
            <Icon asChild>
              <ChevronDown className="h-4 w-4 opacity-50" />
            </Icon>
          </div>
        </Trigger>

        <Portal>
          <Content
            className="relative z-[51] min-w-[8rem] overflow-hidden rounded-md border bg-white dark:bg-gray-800 text-popover-foreground shadow-md animate-in fade-in-80"
            position="popper"
            sideOffset={5}
            onCloseAutoFocus={(e) => {
              // Prevent Radix from managing focus on close
              e.preventDefault();
            }}
          >
            {isSearchable && (
              <DismissableLayer
                onInteractOutside={(e) => {
                  // Prevent closing when clicking the search input
                  if (searchInputRef.current?.contains(e.target as Node)) {
                    e.preventDefault();
                  }
                }}
              >
                <div
                  className="flex items-center border-b px-3 py-2 bg-white dark:bg-gray-800"
                  onMouseDown={(e) => {
                    // Prevent the select from handling mousedown
                    e.stopPropagation();
                  }}
                >
                  <Search className="h-4 w-4 opacity-50 mr-2" />
                  <input
                    ref={searchInputRef}
                    className="flex h-8 w-full rounded-md bg-transparent py-2 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                    placeholder={searchPlaceholder}
                    value={searchTerm}
                    onChange={handleSearch}
                    onClick={handleInputClick}
                    onMouseDown={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                      // Prevent select from handling keyboard events
                      e.stopPropagation();
                    }}
                    onFocus={() => {
                      requestFocus(SELECT2_FOCUS_ID);
                    }}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                      // Only release focus if we're not clicking within the select
                      if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                        releaseFocus(SELECT2_FOCUS_ID);
                      }
                    }}
                  />
                </div>
              </DismissableLayer>
            )}
            <Viewport className="p-1 bg-white dark:bg-gray-800">
              {filteredOptions.length === 0 ? (
                <div className="py-6 text-center text-sm text-muted-foreground">
                  {noOptionsMessage}
                </div>
              ) : (
                filteredOptions.map((option) => (
                  <Item
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                    className={cn(
                      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
                      "cursor-pointer hover:bg-accent hover:text-accent-foreground"
                    )}
                  >
                    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                      <ItemIndicator>
                        <Check className="h-4 w-4" />
                      </ItemIndicator>
                    </span>
                    {customOptionRender ? (
                      customOptionRender(option)
                    ) : (
                      <div>
                        <ItemText>{option.label}</ItemText>
                        {option.description && (
                          <div className="text-xs text-muted-foreground">
                            {option.description}
                          </div>
                        )}
                      </div>
                    )}
                  </Item>
                ))
              )}
            </Viewport>
          </Content>
        </Portal>
      </Root>
    );
  }
);

Select2.displayName = "Select2";

export { Select2 };