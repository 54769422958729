import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { __, TranslationOptions, translationSources } from "@/utils/translationUtils"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatSecondsToMinutesAndSeconds = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return __("{{minutes}}m {{seconds}}s", {
    source: translationSources.audio,
    placeholders: { minutes, seconds: remainingSeconds }
  });
};

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const locale = __("en-US", { source: translationSources.settingsValues });
  const monthFormat = __("short", { source: translationSources.settingsValues }) as Intl.DateTimeFormatOptions['month'];
  const dayFormat = __("numeric", { source: translationSources.settingsValues }) as Intl.DateTimeFormatOptions['day'];

  return date.toLocaleDateString(locale, {
    month: monthFormat,
    day: dayFormat
  });
}