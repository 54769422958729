'use client';

import React, { createContext, useContext, useState, useCallback } from 'react';
import { RecordingState } from "@/constants/events";

interface NoteTakerContextType {
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  mode: 'tts' | 'stt';
  setMode: (value: 'tts' | 'stt') => void;
  recordingState: RecordingState;
  setRecordingState: (value: RecordingState) => void;
  toggleExpanded: () => void;
}

const NoteTakerContext = createContext<NoteTakerContextType | undefined>(undefined);

export const NoteTakerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [mode, setMode] = useState<'tts' | 'stt'>('stt');
  const [recordingState, setRecordingState] = useState<RecordingState>(RecordingState.IDLE);

  const toggleExpanded = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);

  return (
    <NoteTakerContext.Provider value={{
      isExpanded,
      setIsExpanded,
      mode,
      setMode,
      recordingState,
      setRecordingState,
      toggleExpanded
    }}>
      {children}
    </NoteTakerContext.Provider>
  );
};

export const useNoteTaker = () => {
  const context = useContext(NoteTakerContext);
  if (context === undefined) {
    throw new Error('useNoteTaker must be used within a NoteTakerProvider');
  }
  return context;
};